<template>
  <div class="focusList">
    <div class="focusListMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="userItem" v-for="item in list" :key="item.id">
          <div class="users">
            <router-link
              tag="div"
              :to="'/userHomepage?uid=' + item.uid"
              class="userAvatar"
            >
              <ImgDecypt class="avatarImg" :src="item.portrait" />
            </router-link>
            <div class="infoBox">
              <div class="userName">{{ item.name }}</div>
              <div class="introduction">
                {{ item.summary || "这个人很懒，还没有个性签名" }}
              </div>
            </div>
          </div>
          <div class="followedBtn" @click="careOrcancle(item)"></div>
          <!-- <div
            class="focusBtn"
            :class="{ followedBtn: item.isFollow }"
            @click="careOrcancle(item)"
          >
            {{ item.isFollow ? "已关注" : " " }}
          </div> -->
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryFollowList } from "@/api/mine";
import { careOrcancle } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { Toast } from "vant";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList("");
  },
  mounted() {},
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411
      };
      try {
        let res = await this.$Api(queryFollowList, req);
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = i.hasFollow;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    // 关注-取消关注
    async careOrcancle(item) {
      let req = {
        followUID: parseInt(item.uid),
        isFollow: false,
      };
      this.$store.commit("app/SET_LOADING", false);
      try {
        let res = await this.$Api(careOrcancle, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          // console.log(item.isFollow);
          this.pageNumber = 1;
          this.getList("refresh");
          Toast("取消成功");
          // item.isFollow = req.isFollow;
          // console.log(item.isFollow);
          return;
        }
        Toast(res.tip || "取消失败");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", true);
        Toast("操作失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.focusList {
  height: 100%;
  padding-top: 25px;

  .focusListMain {
    height: calc(100% - 44px);
    // padding: 18px 12px 0;
    // box-sizing: border-box;

    .userItem {
      background: #fff;
      border-radius: 8px;
      padding: 0px 10px;
      box-sizing: border-box;
      width: 355px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto 16px;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);

      .users {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .userAvatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        /deep/ .vanImage {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #130f3200 !important;
        }

        /deep/ .van-image__error {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #130f3200 !important;
        }

        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .infoBox {
        margin: 0 12px;
        .userName {
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          color: #333333;
        }

        .introduction {
          width: 200px;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #999999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .focusBtn {
        width: 60px;
        height: 24px;
        background-image: url("~@/assets/png/followBtn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        // font-size: 12px;
        // border-radius: 12px;
        // color: #94D6DA;
        // border: solid #94D6DA 1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .followedBtn {
        width: 65px;
        height: 26px;
        background-image: url("~@/assets/png/followedBtn.png");
        background-size: 100% 100%;
      }
    }
  }
}
</style>
