<template>
  <div class="focusList">
    <HeadBar titleName="我的关注"></HeadBar>
    <van-tabs
      class="navTabs"
      swipeable
      animated
      v-model="tabActive"
      background="rgba(0,0,0,0)"
    >
      <van-tab title="话题">
        <div class="content">
          <Topics v-if="tabActive == 0" />
        </div>
      </van-tab>

      <van-tab title="博主">
        <div class="content">
          <Blogger v-if="tabActive == 1" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import Blogger from "./components/Blogger.vue";
import Topics from "./components/Topics.vue";
import HeadBar from "@/components/HeadBar";

export default {
  components: {
    Topics,
    Blogger,
    HeadBar,
  },
  data() {
    return {
      tabActive: Number(sessionStorage.getItem("active")) || 0,
    };
  },
  watch: {
    tabActive(newVal) {
      sessionStorage.setItem("active", newVal);
    },
  },
  created() {},
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-tabs__wrap {
  width: 342px;
  height: 33px;
  padding: 3.5px 4px 3.5px 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #f0f0f3;
  margin: 20px auto 0;
}

/deep/ .van-tabs__line {
  display: none;
}
/deep/ .van-tabs__nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/deep/ .van-tab--active {
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: #f0f0f3;
}
/deep/ .van-tab {
  padding: 0;
  width: 162px;
  height: 26px;
  border-radius: 8px;
}

.content {
  // padding: 0 10px;
  overflow: auto;
  height: calc(100vh - 88px);
}

.focusList {
  height: 100%;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .focusListMain {
    // height: calc(100% - 44px);
    // padding: 18px 12px 0;

    .userItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      background: #f5f5f5;

      .userAvatar {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;

        /deep/ .vanImage {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #130f3200 !important;
        }

        /deep/ .van-image__error {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #130f3200 !important;
        }

        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .infoBox {
        .userName {
          font-size: 18px;
          margin-bottom: 6px;
          color: #000;
        }

        .introduction {
          width: 200px;
          font-size: 15px;
          color: #827f9d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .focusBtn {
        width: 50px;
        height: 25px;
        border-radius: 25px;
        line-height: 25px;
        font-size: 14px;
        color: #fff;
        background: #999999;
        text-align: center;
      }

      .followedBtn {
        width: 50px;
        height: 25px;
        border-radius: 25px;
        line-height: 25px;
        font-size: 12px;
        color: #fff;
        background: #7145e7;
        text-align: center;
      }
    }
  }
}
</style>
