<template>
  <div class="focusList">
    <div class="focusListMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="userItem" v-for="item in list" :key="item.id">
          <router-link
            tag="div"
            :to="'/topicDetails?id=' + item.id"
            class="userAvatar"
          >
            <ImgDecypt class="avatarImg" :src="item.coverImg" />
          </router-link>
          <div class="infoBox">
            <div class="userName">{{ item.name }}</div>
            <div class="infoData">
              <div class="dataItem">
                <SvgIcon iconClass="star" class="dataIcon"></SvgIcon>
                {{ item.collCount | watchCount }}
              </div>
              <div class="dataItem">
                <SvgIcon iconClass="eye" class="dataIcon"></SvgIcon>
                {{ item.playCount | watchCount }}
              </div>
            </div>
            <div class="introduction">
              {{ item.tagDesc || " " }}
            </div>
          </div>
          <div class="followedBtn" @click="careOrcancle(item)"></div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryHotspotList } from "@/api/mine";
import { collectTag } from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import SvgIcon from "@/components/SvgIcon";
export default {
  components: {
    SvgIcon,
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  computed: {
    ...mapGetters({
      tags: "tags",
    }),
  },
  created() {
    this.getList("");
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        type: "tag",
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
      };
      try {
        let res = await this.$Api(queryHotspotList, req);
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    // 关注-取消关注
    async careOrcancle(item) {
      let req = {
        objID: item.id,
        isCollect: false,
        type: "tag",
      };
      this.$store.commit("app/SET_LOADING", false);
      try {
        let res = await this.$Api(collectTag, req);
        console.log(req, item);
        // this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          // item.hasCollected = !item.hasCollected;
          this.pageNumber = 1;
          this.getList("refresh");
          Toast("取消成功");
          return;
        }
        Toast("取消失败");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", true);
        Toast("操作失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.focusList {
  height: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  .focusListMain {
    height: calc(100% - 44px);
    // padding: 18px 12px 0;
    .userItem {
      width: 355px;
      height: 79px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto 16px;
      border-radius: var(--Number-3, 10px);
      background: #fff;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      padding: 0 10px;
      box-sizing: border-box;

      .userAvatar {
        width: 55px;
        height: 55px;
        border-radius: 4px;
        overflow: hidden;

        /deep/ .vanImage {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background: #130f3200 !important;
        }

        /deep/ .van-image__error {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background-color: #130f3200 !important;
        }

        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      .infoBox {
        height: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // justify-content: space-between;

        .userName {
          color: #333333;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          // margin-bottom: 5px;
        }
        .infoData {
          display: flex;
          align-items: center;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #999;
          .dataItem {
            margin-right: 8px;
            display: flex;
            align-items: center;
            .dataIcon {
              width: 14px;
              height: 14px;
              margin-right: 3px;
            }
          }
        }

        .introduction {
          width: 200px;
          color: #999;
          font-family: "PingFang SC";
          font-size: 10px;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .focusBtn {
        width: 65px;
        height: 26px;
        background-image: url("~@/assets/png/followBtn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        // font-size: 12px;
        // border-radius: 12px;
        // color: #94D6DA;
        // border: solid #94D6DA 1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .followedBtn {
        width: 65px;
        height: 26px;
        background-image: url("~@/assets/png/followedBtn.png");
        background-size: 100% 100%;
        // border-radius: 12px;
        // line-height: 24px;
        // text-align: center;
        // font-size: 12px;
        // color: #fff;
        // font-weight: 500;
        // background: #b3b3b3;
        // text-align: center;
      }
    }
  }
}
</style>
